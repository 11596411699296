import * as React from 'react'

import IndexLayout from '../layouts'
import StyledPage from '../components/StyledPage'
import Container from '../components/Container'
import { HostTable } from '../components/HostTable/HostTable'
import { Link } from 'gatsby'

const IndexPage = () => (
  <IndexLayout>
    <StyledPage>
      <Container>
        <h1>Sites Available</h1>
        <p>
          The following sites are available in the <Link to="/">Search</Link> area. You may filter the sites by bias, or search for specific
          sites matching search criteria:
        </p>
        <HostTable />
      </Container>
    </StyledPage>
  </IndexLayout>
)

export default IndexPage
